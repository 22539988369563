// Dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Components
//import Login from "../Pages/Login/Login.js";
//import PushNotification from "../PushNotificationMain2";
import MainPage from "../Pages/MainPage/MainPage"
import Login from "../Pages/Login/Login"
import Constants from '../constants';
import Storage from '../storage';


export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={Login} />   
        <Route
          path="/4pc-notification"
          component={MainPage} />
        <Route
          path="/logout"
          render={() => {
            Storage.removeItem(Constants.currUserKey);
            window.location = '/';
            return <Login />;
          }
        } />  
        
        <Route component={Login} />                                    
      </Switch>
    </Router>
  );
}

import React from 'react';
import Router from "./Router/router";
import './App.scss';
import {ThemeProvider} from '@material-ui/styles';
import theme from './Theme/theme';
import TwoWayHeader from './Components/TwoWayHeader/TwoWayHeader'
import Layout from './Components/Layout/Layout';

const App = () => {
  return (
       
      <ThemeProvider theme={theme}>
            <Router/>
      </ThemeProvider>
  );
}


export default App;
/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
  /**
   * Represents the current user key.
   */
  get currUserKey() {
    return 'cusr';
  },
 /**
   * Represents the Session ID.
   */
  get sessionID() {
    return 'sessionID';
  },
  /**
    * Represents the Session ID key.
    */
  get sessionIDKey() {
     return 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid';
  }
};

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;

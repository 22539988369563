import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import { Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Storage from '../../storage';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Constants from '../../constants';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: "#235789",
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
  },
  title: {
    fontfamily: 'Open Sans', 
    display: 'none',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },  
  today: {
    fontfamily: 'Open Sans', 
    fontSize : '16px',
    display: 'none',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },  
  dateNow: {
    fontfamily: 'Open Sans', 
    fontSize : '18px',
    display: 'none',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },   
  grow: {
    flexGrow: 1,
  },  
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },  
  sectionMobile: {
    display: 'flex',
    textAlign : 'right',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }, 
}));

export default function Header() {
  const [state, setState] = useState({
    mobileView: false
  })
  const { mobileView } = state;
  
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const loginName = Storage.getItem("Name");
  const { header, logo } = useStyles();
  const classes = useStyles();
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const menuId = 'primary-search-account-menu';
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    Storage.removeItem(Constants.currUserKey);
    window.location = '/';
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const date = format(new Date(), 'EEEE - LLLL d, yyyy');


  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} >Logout</MenuItem>
    </Menu>
  );  

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose} >Logout</MenuItem>
    </Menu>
  );



  const displayDesktop = () => {
    return <Toolbar>
             <img src="assets/logo.png" />
             <Typography className={classes.title} variant="h6" noWrap>
              Desktop Notifier Configuration
             </Typography>
             <div className={classes.grow} />
             <Typography className={classes.today} variant="h6" noWrap>
              Today :
             </Typography>
             <Typography className={classes.dateNow} variant="h6" noWrap>
              {date}
             </Typography>  
             <div className={classes.sectionDesktop}>
             <IconButton
                edge="end"
                aria-label="Calendar"
                color="inherit"
              >
                <EventOutlinedIcon />
              </IconButton>               
              </div>      
              <Box m={3} />  
             <div className={classes.sectionDesktop}>            
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>             
             <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>             
           </Toolbar>;
  };

  const displayMobile = () => {
    return <Toolbar>
             <Tooltip title="Desktop Notifier Configuration">
             <img src="assets/logo.png" />
             </Tooltip>
             <div className={classes.grow} />
             <div className={classes.sectionMobile}>
             <Tooltip title={loginName}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              </Tooltip>
            </div>             
             <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>             
           </Toolbar>;
  };


  return (
    <header>
      <AppBar position="fixed" className={header}>{mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </header>
  );
}
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './init';
import App from './App';
import Overlay from './Pages/Overlay/Overlay';

// Mobile fix for 100vh.
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

ReactDOM.render(
  <Suspense fallback={<Overlay show={true} />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);


import React, { forwardRef,Component } from 'react'
import axios from 'axios';
import './PushNotificationMain.scss';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextsmsTwoToneIcon from '@material-ui/icons/TextsmsTwoTone';
import ScheduleTwoToneIcon from '@material-ui/icons/ScheduleTwoTone';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Header from './Pages/Header/Header'
import Overlay from './Pages/Overlay/Overlay';
import MuiAlert from '@material-ui/lab/Alert';
import Storage from './storage';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import {
    isPushNotificationSupported,
    askUserPermission,
    registerServiceWorker,
    createNotificationSubscription,
    getUserSubscription,
    unRegisterServiceWorker,
    findServiceWorker
  } from "./push-notifications";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};  
  
const pushNotificationSupported = isPushNotificationSupported();
const classes = makeStyles((theme: Theme) =>
createStyles({
  button: {
    margin: theme.spacing(5),
  },
  root: {
    flexGrow: 1,
  } ,
  title: {
    fontfamily: 'Open Sans', 
    fontSize : '20px',
    display: 'none',
    color: "#1d2b58",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },  
  label: {
    fontfamily: 'Open Sans', 
    fontSize : '16px',
    fontWeight : '600',
    display: 'none',
    color: "#000000",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },    
}),
);

const columns = [
    { field: 'id', title: '#', width: 50 , editable: 'never'},
    {
      title: 'CoverKey',
      field: 'coverKey',
      width: 70
    },  
    { field: 'practice', title: 'Practice Name', width: 150 , editable: 'never' },
    { field: 'orgText', title: 'Org Name', width: 150 , editable: 'never' },
    { field: 'contact', title: 'Contact #', width: 80 , editable: 'never' },
    { field: 'address', title: 'Address', width: 150 , editable: 'never' },
  ]; 


class PushNotificationComponent extends Component {
    constructor(props) {
          super(props);    
  
      this.state = {
        data : [],
        recordsFound : '',
        SnackbarMessage: '',
        errorSeverity: '',
        openSnackBar : false,
        showLoadingOverlay: false,
        subscriptionData: false,
        smsStatus : true,
        newbookingStatus : true,
      };  
      
    };


  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
   try
    { 
      
      const apiKey = `${process.env.REACT_APP_API_KEY}`; 
      this.setState(() => ({ showLoadingOverlay: true }));
      const name = Storage.getItem("Name");
      const url = `${process.env.REACT_APP_ADMIN_NOTIF_API}/getNotificationTypesPerUser?secretCode=${apiKey}&userId=${name}`;
      const res =  await axios.post(url);
  
      if (res?.data) {
          const d = res.data;
          d.map(item => {
          let enableItem = item.enabled;
          if (item.notificationType == 1)
            this.setState(() => ({ newbookingStatus: enableItem }));          
          if (item.notificationType == 2)
            this.setState(() => ({ smsStatus: enableItem }));   
          });
      }
  
      this.setState(() => ({showLoadingOverlay: false  }));


      if (pushNotificationSupported)
      { 
        var that = this;  
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          // Service worker found!
          // Automatic update of Service-Worker
          askUserPermission().then(consent => {
              if (consent !== "granted") {
                  console.log(consent);
              }
              else
              {
                  console.log("Granted.");
                  registerServiceWorker();
                  createNotificationSubscription()
                  .then(subscription => {
                    console.log("Subcription created.");
                    const apiKey = `${process.env.REACT_APP_API_KEY}`;
                    const name = Storage.getItem("Name");
                    const url = `${process.env.REACT_APP_NOTIF_API}/subscribe2`;
                    const data = {'userId':name , 'subscription': subscription};
                    axios.post(url, data)
                    .then(res => {
                      const data = res.data;
                      that.setState(() => ({  subscriptionData:true}));
                  });
                  that.setState(() => ({SnackbarMessage: 'Service Woker updated!',errorSeverity: 'info',openSnackBar: true }));                  
                  })
                  .catch(err => {
                    that.setState(() => ({SnackbarMessage: 'Couldn\'t create the notification subscription!',errorSeverity: 'error',openSnackBar: true }));                  
                    console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                  });   
              }
          }); 
        });
      }
    }
    catch{
        this.setState(() => ({  subscriptionData:false}));   
    }
}   

handleChange = async (event) => {

  let disabled = event.target.checked;
  let display = '';
  if (event.target.checked)
  {
    display = 'Disabled';
    disabled = false;
  }
   else
   {
      disabled = true;
      display = 'Enabled';  
   }   


  //let disabled = event.target.checked;
  //setState({ ...state, [event.target.name]: event.target.checked });
  this.setState(() => ({[event.target.name]: event.target.checked}));

  let notificationType = 0;


  if (event.target.name == "sms")
  {
      notificationType = 2;
      this.setState(() => ({ smsStatus: event.target.checked }));  
  }
  if (event.target.name == "newbooking")
  {
      notificationType = 1; 
      this.setState(() => ({ newbookingStatus: event.target.checked }));  
  } 

  const apiKey = `${process.env.REACT_APP_API_KEY}`; 
 // this.setState(() => ({ showLoadingOverlay: true }));
  const name = Storage.getItem("Name");
  const url = `${process.env.REACT_APP_ADMIN_NOTIF_API}/toggleNotifierTypeSubscription?secretCode=${apiKey}&notificationType=${notificationType}&disable=${disabled}&userId=${name}`;
  const res =  await axios.post(url);
  let ret = res?.data;
  if (ret) 
      this.setState(() => ({SnackbarMessage: 'Settings change to disabled successfully!',errorSeverity: 'info',openSnackBar: true }));
  else
      this.setState(() => ({SnackbarMessage: 'Settings change to enabled successfully!',errorSeverity: 'info',openSnackBar: true }));

   
};

InitNotifications = async () =>
{
    const apiKey = `${process.env.REACT_APP_API_KEY}`; 
    this.setState(() => ({ showLoadingOverlay: true }));
    const name = Storage.getItem("Name");
    const url = `${process.env.REACT_APP_ADMIN_NOTIF_API}/getNotificationTypesPerUser?secretCode=${apiKey}&userId=${name}`;
    const res =  await axios.post(url);

    if (res?.data) {
        const d = res.data;
        d.map(item => {
         let enableItem = item.enabled;
      /*   if (item.enabled)
              enableItem = false;
          else
             enableItem = true; */

          if (item.notificationType == 1)
          {

            this.setState(() => ({ newbookingStatus: enableItem }));          
          }
          if (item.notificationType == 2)
          {
            this.setState(() => ({ smsStatus: enableItem }));   
          }
        });
    }

  this.setState(() => ({showBlockCoverkey:false, showToggleNotification:true,showLoadingOverlay: false  }));
}


  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  onClickEnableNotification = async () => {
    if (pushNotificationSupported)
    {
        var that = this;
        askUserPermission().then(consent => {
        if (consent !== "granted") {
            this.setState(() => ({SnackbarMessage: 'Notification denied! Please change your settings.',errorSeverity: 'info',openSnackBar: true }));
        }
        else
        {
            that.setState(() => ({ showLoadingOverlay: true }));
            console.log("Granted.");
            registerServiceWorker();
            createNotificationSubscription()
            .then(subscription => {
              console.log("Subcription created.");  
              const headers = {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                "X-Requested-With": "XMLHttpRequest"
                };
              const apiKey = `${process.env.REACT_APP_API_KEY}`;
              const name = Storage.getItem("Name");
              const url = `${process.env.REACT_APP_NOTIF_API}/subscribe2`;
              const data = {'userId':name , 'subscription': subscription};
              axios.post(url, data, {headers : headers})
              .then(res => {
                const data = res.data;
                that.setState(() => ({subscriptionData:true,  SnackbarMessage: 'Notification enabled.',errorSeverity: 'info',openSnackBar: true }));
                that.setState(() => ({ showLoadingOverlay: false }));
              })
              .catch( err =>{
                console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                that.setState(() => ({subscriptionData:false,  SnackbarMessage: 'Couldn\'t create the notification subscription',errorSeverity: 'error',openSnackBar: true }));
                that.setState(() => ({ showLoadingOverlay: false }));
              }
              );
            })
            .catch(err => {
              console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
              that.setState(() => ({subscriptionData:false,  SnackbarMessage: 'Couldn\'t create the notification subscription',errorSeverity: 'error',openSnackBar: true }));
              that.setState(() => ({ showLoadingOverlay: false }));
            });
        }
        });
    }
  };

    /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  onClickDisableNotification = () => {
    if (pushNotificationSupported)
    {
        unRegisterServiceWorker();
        this.setState(() => ({ subscriptionData:false,  SnackbarMessage: 'Notification disabled.',errorSeverity: 'info',openSnackBar: true }));
    }
  };

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  onClickSendSubscriptionToPushServer = async (typeNotif,notifIcon) => {
    const apiKey = `${process.env.REACT_APP_API_KEY}`;
    const name =  Storage.getItem("Name");
    const url = `${process.env.REACT_APP_NOTIF_API}/demo2`;
    let messageBody = (typeNotif == "SMS") ? "You have a new text message!" : "You have a new bookings!";
    let urlLink = (typeNotif == "SMS") ? "SMS" : "WebSchedule";
    const message = {type: typeNotif,message:messageBody,url:urlLink,title:"4PC Notification Service", icon:notifIcon};
    const data = {'userId':name , 'message': JSON.stringify(message)};
    const res = await axios.post(url, data);
    if (res?.data) {
        const d = res.data;
        this.setState(() => ({  SnackbarMessage: 'Message (' + typeNotif + ') sent successfully!',errorSeverity: 'info',openSnackBar: true }));
    }        
  };  


    render() {

        const {
            data,
            recordsFound,
            SnackbarMessage,
            errorSeverity,
            openSnackBar,
            showLoadingOverlay,
            subscriptionData,
            smsStatus,
            newbookingStatus,
        } = this.state;       
     
        return (
            <React.Fragment>
                <Box p={4} bgcolor="background.paper">
                <Header/>  
                </Box>
                <Box p={2} bgcolor="background.paper">
                <Grid container  spacing={2}>
                    <Grid item xs={12} className={classes.canvasHeader}> 
                        <Typography className={classes.title} variant="h6" noWrap>
                          Incoming Notification Settings
                        </Typography>
                    </Grid>
                </Grid>
                </Box>         
                <Divider/> 
                <Box p={2} bgcolor="background.paper">
                  <Grid container  spacing={2}>
                    <Grid item xs={6} className={classes.canvasHeader}>                   
                     <FormControlLabel
                         control={<Checkbox checked={smsStatus} 
                         color="default"
                         onChange={this.handleChange}
                         name="sms" />}
                         label={<Typography className={classes.label}>Enable SMS Notification</Typography>}
                      />    
                    </Grid>
                    <Grid item xs={6} className={classes.canvasHeader}>                 
                      <FormControlLabel
                        control={<Checkbox checked={newbookingStatus}  
                        color="default"
                        onChange={this.handleChange}
                        name="newbooking" />}
                        label={<Typography className={classes.label}>Enable New Booking Notification</Typography>}
                      />       
                    </Grid>         
                   </Grid>                                          
                </Box>   
                <Divider/>  
                <Box p={2} bgcolor="background.paper">
                <Grid container  spacing={2}>
                    <Grid item xs={12} className={classes.canvasHeader}> 
                        <Typography className={classes.title} variant="h6" noWrap>
                          Send Test Notification 
                        </Typography>
                    </Grid>
                </Grid>
                </Box>         
                <Divider/> 
                <Box p={1} bgcolor="background.paper">
                  <Grid container  spacing={2}>
                      <Grid item xs={12} className={classes.canvasHeader}> 
                        <Grid container  spacing={0}>
                          <Grid item>
                            <Box p={2} bgcolor="background.paper">
                                <Button
                                      style={{maxWidth: '250px', maxHeight: '38px', minWidth: '250px', minHeight: '38px'}}
                                      disabled = {!subscriptionData}
                                      variant="contained"
                                      color="default"
                                      className={classes.button}
                                      startIcon={<TextsmsTwoToneIcon />}
                                      onClick={() => { this.onClickSendSubscriptionToPushServer("SMS","sms.png") }}
                                      >
                                      Send Test SMS
                                </Button>  
                            </Box>
                          </Grid>
                          <Grid item>
                             <Box p={2} bgcolor="background.paper">
                               <Button
                                    style={{maxWidth: '250px', maxHeight: '38px', minWidth: '250px', minHeight: '38px'}} 
                                    disabled = {!subscriptionData}
                                    variant="contained"
                                    color="default"
                                    className={classes.button}
                                    startIcon={<ScheduleTwoToneIcon />}
                                    onClick={() => { this.onClickSendSubscriptionToPushServer("Bookings","notepad.png") }}
                                    >
                                        Send Test Bookings
                                </Button> 
                              </Box>   
                          </Grid>                          
                        </Grid>
                      </Grid>
                  </Grid>
                </Box>  
                <Box p={5} bgcolor="background.paper"></Box>                          
                <Overlay show={showLoadingOverlay}>
                    <i className="spinner-eclipse"></i>
                </Overlay>      
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnackBar} autoHideDuration={3000} onClose={() => this.setState({openSnackBar: false})}>
                    <Alert onClose={() => this.setState({openSnackBar: false})} severity={errorSeverity}>
                    {SnackbarMessage} 
                    </Alert>
                </Snackbar>                                                        
         </React.Fragment>          
        );      
    }    
}

export default PushNotificationComponent;
import './MainPage.scss';
import React, { Component } from 'react';
import Constants from '../../constants';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Utils from '../../utils';
import Storage from '../../storage';
import queryString from 'query-string';
import Enums from '../../enums';
import PushNotificationPage from '../../PushNotificationMain3';

class MainPage extends Component {
  constructor(props) {
        super(props);    

    this.state = {
        redirectToLogin: false
    };    
   // this.logoUrl = Utils.getLogoUrl();
  };
  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const value=queryString.parse(this.props.location.search);
    const sessionIDParam=value.SessionID;
    if (sessionIDParam)
    {
      try {
          const secretCode = process.env.REACT_APP_API_KEY;
          const url = `${process.env.REACT_APP_AUTHENTICATION_API}/LoginSession?sessionID=${sessionIDParam}&secretCode=${secretCode}`;
          const res = await axios.post(url);
          const jwt = res.data;
          const jwtData = Utils.decodeJwt(jwt);
          let user = Storage.getItem(Constants.currUserKey);
          let sid = jwtData[Constants.sessionIDKey];
          Storage.setItem("userRole", jwtData.role);
          Storage.setItem("sessionID", sid);
          Storage.setItem("Name", jwtData.unique_name);

          if (!user) {
            user = {
              jwt: ''
            };
          }
  
          user.jwt = jwt;
          Storage.setItem(Constants.currUserKey, user);    
          this.setState(() => ({
            redirectToLogin: false
        }));
        }
        catch (error) {
          if ((error && !error.response) || (error && error.response.status === Enums.HttpStatusCodes.httpStatusUnauthorizedError)) {
              this.setState(() => ({
                redirectToLogin: true
            }));
          }
          else if ((error && !error.response) || (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)) {
              this.setState(() => ({
                redirectToLogin: true
            }));
          }
        }                
    }
    else
    {
        const user = Storage.getItem(Constants.currUserKey);
        if (user && user.jwt) {
            try {
              const jwtCheckUrl = `${process.env.REACT_APP_AUTHENTICATION_API}/jwtCheck`;
              await axios.get(jwtCheckUrl);
            }
            catch {
                this.setState(() => ({
                    redirectToLogin: true
                }));
            }
        } 
        else {
            this.setState(() => ({ redirectToLogin: true }));
        }  
    }     
  }

  render() {
      const {
        redirectToLogin
      } = this.state;
  
      if (redirectToLogin) {
        return <Redirect to={{ pathname: '/' }} />;
      } else {
         return <PushNotificationPage/>;
      }
    }    
} 

export default MainPage;

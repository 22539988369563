import React, { Component } from 'react';
//import Utils from '../../utils';
import NormalLogin from './NormalLogin';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import Overlay from '../Overlay/Overlay';
import './Login.scss';
import Box from '@material-ui/core/Box';
import Header from '../Header/LoginHeader'

/**
 * Represents the main login component for the admin dashboard.
 */
class Login extends Component {
  /**
   * Initializes a new instance of the Login component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      redirectToNormalLogin: false,
      showLoadingOverlay: false,
      username: ''
    };

  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    this.setState(() => ({
      isVisible: true,
      redirectToNormalLogin: true,
      showLoadingOverlay: false
    }));

  }

  componentWillUnmount() {
    //Events.removeAllListeners(Constants.Events.tempLogin);
  }



  /**
   * Renders the component.
   */
  render() {
    const {
      isVisible,
      redirectToNormalLogin,
      showLoadingOverlay
    } = this.state;


    let currComponent = null;

    if (redirectToNormalLogin) {
      currComponent = <NormalLogin />;
    }

    return (
      <div className="login">
       <Box p={4} bgcolor="background.paper">
      <Header/>  
      </Box> 
          <section className="login__content">
          <NormalLogin />
          </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default Login;
